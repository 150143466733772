import React, { useEffect, useState } from 'react';
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import {
  Grid,
  GridItem,
  Flex,
  Heading,
  Center,
  Box,
  Text
} from '@chakra-ui/react';
import GatesService from '../../services/gates';

import {
  TitleWrapper,
  TableWrapper,
} from './Dashboard.styles';

const gates = localStorage.getItem('gates') ? JSON.parse(localStorage.getItem('gates')) : ({gates: []});
const Dashboard = () => {
  const [lives, setLives] = useState([]);
  const [retry, setRetry] = useState(localStorage.getItem('retryCounter') ? Number(localStorage.getItem('retryCounter')) : 0);
  useEffect(() => {
    setTimeout(() => {
      if(gates.gates.length === 0) {
        if (retry < 5) {
          setRetry((prevState) => {
            const newState = prevState + 1;
            localStorage.setItem('retryCounter', newState)

            return newState;
          });
          window.location = '/';
        } else {
          localStorage.clear();
          window.location = '/sign_in';
        }
      } else {
        setRetry(0);
        fetchLives();
      }
    }, 2000)
  }, []);

  const fetchLives = async() => {
    const gatesResponse = await GatesService.index();
    if (gatesResponse.status === 200) {
      setLives(gatesResponse.data.lives);
    }
  }

  return (
    <Center>
      <Flex direction="column">
        <TitleWrapper>
          <Center>
            <Heading size='lg'>Seja muito bem vindo, { localStorage.username }</Heading>
          </Center>
        </TitleWrapper>

        <TableWrapper>
          <Center>
            <Flex direction="column">
              <Heading size='md'>Status dos gates</Heading>
              <br />

              {gates.gates.map(gate => (
                <Box>
                  <Flex justifyContent='space-between' id="flexBox">
                    <Heading size='md' textTransform='uppercase'>
                      {gate.name?.split('_')?.join(' ')}
                    </Heading>
                    {gate.live? (
                      <Text pt='2' fontSize='md' color='lightGreen'>
                        LIVE
                      </Text>
                    ) : (
                      <Text pt='2' fontSize='md' color='red'>
                        MANUTENÇÃO
                      </Text>
                    ) }
                  </Flex>
                </Box>
              ))}

              {lives.length > 0 ? (
                <>
                  <br />
                  <Heading size='md'>Ultimas lives</Heading>
                  <br />

                  {lives.map(live => (
                    <Box>
                      <Flex justifyContent='space-between' id="flexBox">
                        <Text pt='2' fontSize='md'>
                          {live.card_number} | {live.expiration_date.split('/')[0]}
                          {" "}| {live.expiration_date.split('/')[1]} | {live.cvv}
                          {" "}| {live.brand} | {live.bank} | {live.scheme}
                          {" "}| {live.card_type} | {live.country}
                        </Text>
                      </Flex>
                    </Box>
                  )) }
                </>
              ) : ('') }
            </Flex>
          </Center>
        </TableWrapper>
      </Flex>
    </Center>
  );
}

export default Dashboard;
