import styled from 'styled-components';

import {
  Heading,
  Button,
} from '@chakra-ui/react';

export const Title = styled(Heading)`
  color: white;
  font-size: 22px;
`;

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
`;

