import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  Flex,
  Heading,
  Textarea,
  Button,
  Box,
  Divider,
  useDisclosure,
  Center,
  Grid,
  GridItem,
  Input,
} from '@chakra-ui/react';

import {
  Title,
  Centered,
} from './Recharge.styles';

import SessionService from '../../services/session';
import PaymentService from '../../services/payment';

const Plans = (props) => {
  const { onClose } = useDisclosure();
  const [credits, setCredits] = useState(0);
  const [amount, setAmount] = useState(50);
  const [qrCode, setQrCode] = useState({});
  const [paymentId, setPaymentId] = useState('');
  const [paid, setPaid] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchSession = async () => {
    const response = await SessionService.show();
    setCredits(response.data.credits);
  };

  const handleSubmit = async () => {
    if (amount >= 50 && amount <= 500) {
      setLoading(true)
      const params = { amount };
      const response = await PaymentService.create(params);
      setPaymentId(response.data.id)
      const currentPaymentId = response.data.id;
      setQrCode({ text: response.data.qrCodeText, base64: response.data.qrCodeBase64 });
      setLoading(false)

      setInterval(async () => {
        try {
          const paymentResponse = await PaymentService.show(currentPaymentId)
          const status = paymentResponse.data.status
          if (status === 'paid') {
            toast.success('Finalizado com sucesso');
            clearInterval(paymentId)
            setPaid(true)

            setTimeout(() => {
              window.location.href = '/'
            }, 5000)
          }
          if (status === 'canceled') {
            toast.error('Pagamento cancelado!');
            clearInterval(paymentId)
            setTimeout(() => {
              window.location.href = '/'
            }, 3000)
          }
        } catch (error) {
          console.error('Failed to fetch payment status:', error)
        }
      }, 5000)
    } else {
      toast.error('O valor deve ser maior que R$ 50,00 e menor que R$ 500,00');
    }
  }

  const copyToClipboard = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        toast.success('Copiado com sucesso');
      })
      .catch((err) => {
        toast.error('Erro ao copiar');
      })
  }

  useEffect(() => {
    onClose();
    fetchSession();
  }, []);

  return (
    paymentId === '' ? (
      <>
        <Grid
          templateAreas={`"header header"
      "main main"
      `}
          gridTemplateRows={'50px 1fr 50px'}
          gridTemplateColumns={'150px 1fr'}
          h='80vh'
          gap='2'
          color='blackAlpha.700'
          fontWeight='bold'
        >
          <GridItem pl='2' area={'header'}>
            <Title as="h1" size="xl">
              Recarga
            </Title>
          </GridItem>
          <GridItem pl='2' area={'main'} style={{ color: 'white' }}>
            <Centered>
              <Flex direction="column">
                <label>Valor da recarga</label>
                <Flex direction="row">
                  <Input type="number" name="amount" value={amount} onChange={e => { setAmount(parseInt(e.target.value)) }} placeholder="Valor da recarga" />
                  { loading ? (
                    <Button colorScheme='teal' variant='outline' type='button' isLoading />
                  ) : (
                    <Button colorScheme='teal' variant='outline' type='button' onClick={() => { handleSubmit() }}>
                      Recarregar
                    </Button>
                  ) }
                </Flex>
              </Flex>
            </Centered>
          </GridItem>
        </Grid>
      </>
    ) : (
      <>
        <Flex direction="column">
          <Heading size='md'>{paid ? ('Pago com sucesso') : ('Efetue o pagamento')}</Heading>
        </Flex>

        <br />

        {paid ? ('') : (
          <Flex direction="column">
            <label>Pix Copia e Cola</label>
            <Flex direction="row">
              <Input type="text" name="cookie" value={qrCode.text} disabled={true} placeholder="Pix Copia e Cola" />
              <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(qrCode.text) }}>
                Copiar
              </Button>
            </Flex>

            <br />

            <Centered>
              <img src={`data:image/png;base64,${qrCode.base64}`} alt="QrCode" width="400px" height="400px" />
            </Centered>
          </Flex>
        )}
      </>
    )
  )
}

export default Plans;
