import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Flex,
  Heading,
  Textarea,
  Button,
  Progress,
  Box,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';

import {
  Centered,
  CustomThumbUp,
  CustomClose,
  CustomCard,
  ColumOnMobileFlex
} from './VbvChecker.styles';

import VbvCheckerService from '../../services/vbv';

const VbvChecker = (props) => {
  const { onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [checkedCards, setCheckedCards] = useState([]);
  const [finished, setFinished] = useState(false);
  const [total, setTotal] = useState(0);
  const [processed, setProcessed] = useState(0);

  useEffect(() => {
    onClose();
  }, []);

  const cardParams = () => {
    const includingCards = [];

    for (let i=0; i < cards.length; i++) {
      const card = cards[i];
      if (card) {

        includingCards.push({
          card_number: card.split('|')[0],
          expiration_date: `${card.split('|')[1]}/${card.split('|')[2]}`,
          cvv: card.split('|')[3]
        });
      }
    }

    return includingCards;
  }

  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const checkCards = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFinished(false);

    try {
      setTotal(cardParams().length);
      toast.success('O teste das infos começou aguarde até finalizar');

      for (const currentCard of cardParams()) {
        const response = await VbvCheckerService.create({ list: [currentCard] });

        if (response.status === 200) {

          //while (!finished) {
          //await sleep(1000);

          await loadCheckedCards(response.data.id);

          //await sleep(1000);
          //}

        } else {
          toast.error('something went wrong');
        }
      };
    } catch {
      setLoading(false);
      toast.error('something went wrong');
    }
  };

  const loadCheckedCards = async (packId) => {
    if (packId === '0') {
      return false;
    }

    toast.success('Info testada com sucesso');

    try {
      const response = await VbvCheckerService.show(packId);

      if (response.status === 200) {
        let currentProcessed = 0;
        setProcessed(prevState => { currentProcessed = prevState + 1; return prevState + 1 });
        setCheckedCards(prevState => { return [...prevState, ...response.data.cards] });
        setCards(
          cards.filter(
            (card) => response.data.cards.filter((checked) => checked.card_number  !== card.split('|')[0]).length
          )
        );

        if (response.data.status === 'finished') {
          if (currentProcessed >= cardParams().length) {
            setCards([]);
            setFinished(true);
            setLoading(false);
          }
        }
      } else {
        toast.error('something went wrong');
      }
    } catch {
      setLoading(false);
      toast.error('something went wrong');
    }
  };


  const userSession = localStorage.getItem('user_token');

  return (
    userSession ? (
      <>
        <Flex direction="column">
          <Heading size='md'>Gate Selecionada: VBV (3ds) CHECKER</Heading>
          <br />
          <form onSubmit={(e) => { checkCards(e) } }>
            <label>Lista</label>
            <Textarea onChange={(e) => { setCards(e.target.value.split("\n")) }} value={cards.join("\n")} disabled={loading} placeholder="numero|mes|ano|cvv" style={{textAlign: 'center'}} />

            <Centered>
              <br />
              { loading ? (
                <Progress colorScheme='green' value={processed === 0 ? (0) : ((processed/total) * 100)} />
              ) : (
                <Button colorScheme='teal' variant='outline' type='submit'>
                  Checar
                </Button>
              ) }
            </Centered>
          </form>
        </Flex>

        <br />

        <ColumOnMobileFlex justify="space-between">
          <Box maxW='sm' borderWidth='1px' borderRadius='lg' display='flex' padding="10px">
            <Box
              color='green.500'
              fontWeight='semibold'
              letterSpacing='wide'
              textTransform='uppercase'
              display='flex'
              justifyContent='center'
              alignItems='center'
              w={{lg: 'auto', sm: '320px'}}
            >
              <Box ml='8' mr='5'>
                <CustomThumbUp />
              </Box>
              <Divider orientation='vertical' />

              <Box ml='5'>
                <Heading size="md">{checkedCards.filter((card) => (card.live === true)).length}</Heading>
              </Box>

              <Box ml='5' mr='5'>
                <Heading size="md">Aprovados</Heading>
              </Box>
            </Box>
          </Box>

          <Box maxW='sm' borderWidth='1px' borderRadius='lg' display='flex' padding="10px">
            <Box
              color='red.500'
              fontWeight='semibold'
              letterSpacing='wide'
              textTransform='uppercase'
              display='flex'
              justifyContent='center'
              alignItems='center'
              w={{lg: 'auto', sm: '320px'}}
            >
              <Box ml='8' mr='5'>
                <CustomClose />
              </Box>
              <Divider orientation='vertical' />

              <Box ml='5'>
                <Heading size="md">{checkedCards.filter((card) => (card.live === false)).length}</Heading>
              </Box>

              <Box ml='5' mr='5'>
                <Heading size="md">Reprovados</Heading>
              </Box>
            </Box>
          </Box>

          <Box maxW='sm' borderWidth='1px' borderRadius='lg' display='flex' padding="10px">
            <Box
              color='white.500'
              fontWeight='semibold'
              letterSpacing='wide'
              textTransform='uppercase'
              display='flex'
              justifyContent='center'
              alignItems='center'
              w={{lg: 'auto', sm: '320px'}}
            >
              <Box ml='8' mr='5'>
                <CustomCard />
              </Box>
              <Divider orientation='vertical' />

              <Box ml='5'>
                <Heading size="md">{total}</Heading>
              </Box>

              <Box ml='5' mr='5'>
                <Heading size="md">Todos</Heading>
              </Box>
            </Box>
          </Box>
        </ColumOnMobileFlex>

        <br/>
        <Divider />
        <br/>


        <Box
          borderWidth='1px'
          borderRadius='lg'
          display='flex'
          padding="10px"
          flexDirection="column"
          justifyContent="center"
          align="center"
        >
          <Box
            color='white.500'
            fontWeight='semibold'
            letterSpacing='wide'
            textTransform='uppercase'
          >
            <Box ml='8' mr='5' as='heading'>
              Live (SEM VBV)
            </Box>

            { checkedCards.map((checkedCard) => {
              return (
                checkedCard.live ? (
                  <Box as="p">
                    { `${checkedCard.card_number} | ${checkedCard.expiration_date.split('/')[0]} | ${checkedCard.expiration_date.split('/')[1]} | ${checkedCard.cvv} | ${checkedCard.brand} | ${checkedCard.bank} | ${checkedCard.scheme}|${checkedCard.card_type} | ${checkedCard.country}` } | <a href="http://cibertools.info/" target="_blank">#CYBERTOOLS.INFO</a> | <a href="https://t.me/Silverhand101" target="_blank">@Silverhand101</a>
                  </Box>
                ) : ('')
              )
            }) }
          </Box>
        </Box>

        <br/>

        <Box
          borderWidth='1px'
          borderRadius='lg'
          display='flex'
          padding="10px"
          flexDirection="column"
          justifyContent="center"
          align="center"
        >
          <Box
            color='white.500'
            fontWeight='semibold'
            letterSpacing='wide'
            textTransform='uppercase'
          >
            <Box ml='8' mr='5' as='heading'>
              Die (COM VBV)
            </Box>

            { checkedCards.map((checkedCard) => {
              return (
                checkedCard.live ? ('') : (
                  <Box as="p">
                    { `${checkedCard.card_number} | ${checkedCard.expiration_date.split('/')[0]} | ${checkedCard.expiration_date.split('/')[1]} | ${checkedCard.cvv} | ${checkedCard.brand} | ${checkedCard.bank} | ${checkedCard.scheme}|${checkedCard.card_type} | ${checkedCard.country}` }
                  </Box>
                )
              )
            }) }
          </Box>
        </Box>
      </>
    ) : (<Redirect to="sign_in" />)
  );
}

export default VbvChecker;
