import axios from 'axios';

const Api = axios.create({
  headers: {
    'content-type': 'application/json'
  },
  baseURL: 'https://f5search.com.br',
});

const CyberApi = axios.create({
  headers: {
    authorization: `Bearer ${localStorage.getItem("user_token")}`,
    'content-type': 'application/json'
  },
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
});

const ConsultsService = {
  cpf: (params) => Api.get(`/search/serasa?access-key=29f81ea5-8f77-449e-b09b-79c0cb7f3432&cpf=${params.cpf}`),
  phone: (params) => Api.get(`/search/telefone?access-key=29f81ea5-8f77-449e-b09b-79c0cb7f3432&telefone=${params.phone}`),
  name: (params) => Api.get(`/search/f5data?access-key=29f81ea5-8f77-449e-b09b-79c0cb7f3432&nome=${encodeURI(params.name)}`),
  debit: () => CyberApi.post('/consults'),
}

export default ConsultsService;
