import axios from 'axios';

const Api = axios.create({
  headers: {
    authorization: `Bearer ${localStorage.getItem("user_token")}`,
    'content-type': 'application/json'
  },
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  timeout: 900000
});

export default Api;
