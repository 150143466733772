import { Flex, Center, Heading, Divider } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import routes from '../../utils/sidebarRoutes';
import SidebarResponsive from '../Sidebar/SidebarResponsive';
import SessionService from '../../services/session';
import GatesService from '../../services/gates';

const Header = (props) => {
  const [loggedUser, setLoggedUser] = useState(false);
  const [credits, setCredits] = useState(0);
  const { search, pathname } = useLocation();
  const reload = search.includes('reload=true');

  const userSession = async () => {
    try {
      const response = await SessionService.show();
      setCredits(response.data.credits);
      localStorage.setItem('username', response.data.username);

      const gatesResponse = await GatesService.index();
      if (gatesResponse.status === 200) {
        localStorage.setItem('gates', JSON.stringify(gatesResponse.data))
      }
      setLoggedUser(true);
    } catch(e) {
      setLoggedUser(false);

      localStorage.removeItem('user_token');
      localStorage.clear();
      if (pathname !== '/sign_in' && pathname !== '/sign_up') {
        window.location = '/sign_in';
      }
    }
  };

  useEffect(() => {
    async function fetchSession() {
      await userSession();
    }
    fetchSession();
  }, []);

  return(
    reload ? (
      window.location = '/'
    ) : (
      <Flex direction="column" style={{textAlign: 'center'}}>
        <Center w='100vw'>
          <Heading as='h4'>{'< CYBERTOOLS >'}</Heading>
          <br />
        </Center>
        { window.location.pathname === '/sign_in' || window.location.pathname === '/sign_up' ? ('') : (
          `Saldo: ${credits}`
        )
        }
        {loggedUser ? (
          <SidebarResponsive
            routes={routes}
            logoText={'< CYBERTOOLS >'}
            secondary={''}
          />
        ) : ('') }
        <br />
        <Divider />
        <br />
      </Flex>
    )
  );
}

export default Header;
