import styled from 'styled-components';

export const TitleWrapper = styled.div`
  h2 {
    text-align: center;
  }
`;

export const TableWrapper = styled.div`
  margin-top: 50px;

  h2 {
    text-align: center;
  }

  #flexBox {
    border-bottom: 1px solid;
    margin-bottom: 10px;
    max-width: 800px;
    min-width: 500px;
  }

  @media (max-width: 768px) {
    #flexBox {
      min-width: 80vw;
    }
  }
`;
