import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Textarea, Button, Progress, Center, Box, Divider } from '@chakra-ui/react';

import SeparatorService from '../../services/separator';

const Separator = () => {
  const [infos, setInfos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [processed, setProcessed] = useState(0);
  const [checkedInfos, setCheckedInfos] = useState([]);

  const formatInfo = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (infos.length > 0) {
      setTotal(infos.length);
      setProcessed(infos.length / 2);
    }

    try {
      const response = await SeparatorService.create({ list: infos });

      if (response.status === 200) {
        toast.success('Lista separada com sucesso');
        setProcessed(infos.length);
        setCheckedInfos(response.data.cards);
        setInfos([]);
        setLoading(false);
      } else {
        toast.error('something went wrong');
      }
    } catch (e) {
      setLoading(false);
      toast.error('Algo deu errado contate o administrador');
    }
  };

  return (
    <>
      <Flex direction="column">
        <form onSubmit={(e) => { formatInfo(e) } }>
          <label>Lista</label>
          <Textarea onChange={(e) => { setInfos(e.target.value.split("\n")) }} value={infos.join("\n")} disabled={loading} placeholder="lista" />

          <Center style={{marginTop: '20px'}}>
            { loading ? (
              <Progress colorScheme='green' value={processed === 0 ? (0) : ((processed/total) * 100)} />
            ) : (
              <Button colorScheme='teal' variant='outline' type='submit'>
                Separar
              </Button>
            ) }
          </Center>
        </form>
      </Flex>

      <br />
      <Divider />
      <br/>

      <Box
        borderWidth='1px'
        borderRadius='lg'
        display='flex'
        padding="10px"
        flexDirection="column"
        justifyContent="center"
        align="center"
      >
        <Box
          color='white.500'
          fontWeight='semibold'
          letterSpacing='wide'
          textTransform='uppercase'
        >
          <Box ml='8' mr='5' as='heading'>
            Resultado
          </Box>

          { checkedInfos.map((checkedInfo) => {
            return (
              <Box as="p">
                { `${checkedInfo.card_number}|${checkedInfo.month}|${checkedInfo.year}|${checkedInfo.cvv}` }
              </Box>
            )
          }) }
        </Box>
      </Box>
    </>
  );
}

export default Separator;
