/*eslint-disable*/

import { FaListUl } from "react-icons/fa";
import { BiLogOutCircle } from "react-icons/bi";
// chakra imports
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Link,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
    Icon,
    Heading
} from "@chakra-ui/react";
import { Divider } from '@chakra-ui/react';
import React, { useState } from "react";
import { NavLink, useLocation, Redirect } from "react-router-dom";

function SidebarResponsive(props) {
  const [redirect, setRedirect] = useState(false);
    // to check for active links and opened collapses
  const location = useLocation();
    // this is for the rest of the collapses
    const [state, setState] = React.useState({});
    const mainPanel = React.useRef();
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
      return location.pathname === routeName ? "active" : "";
    };
  
  const createLinks = (props) => {
    // Chakra Color Mode
    const activeBg = useColorModeValue("white", "gray.700");
    const inactiveBg = useColorModeValue("white", "gray.700");
    const activeColor = useColorModeValue("gray.700", "white");
    const inactiveColor = useColorModeValue("gray.400", "gray.400");
    const routes = props.routes;
    const resetSession = (e) => {
      e.preventDefault();

      localStorage.removeItem('user_token');
      localStorage.clear();
      props.setRedirect(true);
    };

    return (
      <>
        {
          routes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          if (prop.category) {
            var st = {};
            st[prop["state"]] = !state[prop.state];
            return (
              <div key={prop.name}>
                <Text
                  color={activeColor}
                  fontWeight="bold"
                  mb={{
                    xl: "12px",
                  }}
                  mx="auto"
                  ps={{
                    sm: "10px",
                    xl: "16px",
                  }}
                  py="12px"
                >
                  {document.documentElement.dir === "rtl"
                    ? prop.rtlName
                    : prop.name}
                </Text> 
                {createLinks({routes: prop.views, setRedirect})}
              </div>
            );
          }
            return (
              prop.external ? (
                <a href={prop.path}>
                  <Button
                    boxSize="initial"
                    justifyContent="flex-start"
                    alignItems="center"
                    bg="transparent"
                    mb={{
                      xl: "12px",
                    }}
                    mx={{
                      xl: "auto",
                    }}
                    py="12px"
                    ps={{
                      sm: "10px",
                      xl: "16px",
                    }}
                    borderRadius="15px"
                    _hover="none"
                    w="100%"
                    _active={{
                      bg: "inherit",
                      transform: "none",
                      borderColor: "transparent",
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                  >
                    <Flex>
                      {typeof prop.icon === "string" ? (
                        <Icon>{prop.icon}</Icon>
                      ) : (
                        <Icon>{prop.icon}</Icon>
                      )}
                      <Text color={inactiveColor} my="auto" fontSize="sm">
                        {document.documentElement.dir === "rtl"
                          ? prop.rtlName
                          : prop.name}
                      </Text>
                    </Flex>
                  </Button>
                </a>
              ) : (
                <NavLink to={prop.path} key={prop.name}>
                  {activeRoute(prop.path) === "active" ? (
                    <Button
                      boxSize="initial"
                      justifyContent="flex-start"
                      alignItems="center"
                      bg={activeBg}
                      mb={{
                        xl: "12px",
                      }}
                      mx={{
                        xl: "auto",
                      }}
                      ps={{
                        sm: "10px",
                        xl: "16px",
                      }}
                      py="12px"
                      borderRadius="15px"
                      _hover="none"
                      w="100%"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                    >
                      <Flex>
                        {typeof prop.icon === "string" ? (
                          <Icon>{prop.icon}</Icon>
                        ) : (
                          <Icon>{prop.icon}</Icon>
                        )}
                        <Text color={activeColor} my="auto" fontSize="sm">
                          {document.documentElement.dir === "rtl"
                            ? prop.rtlName
                            : prop.name}
                        </Text>
                      </Flex>
                    </Button>
                  ) : (
                    <Button
                      boxSize="initial"
                      justifyContent="flex-start"
                      alignItems="center"
                      bg="transparent"
                      mb={{
                        xl: "12px",
                      }}
                      mx={{
                        xl: "auto",
                      }}
                      py="12px"
                      ps={{
                        sm: "10px",
                        xl: "16px",
                      }}
                      borderRadius="15px"
                      _hover="none"
                      w="100%"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                    >
                      <Flex>
                        {typeof prop.icon === "string" ? (
                          <Icon>{prop.icon}</Icon>
                        ) : (
                          <Icon>{prop.icon}</Icon>
                        )}
                        <Text color={inactiveColor} my="auto" fontSize="sm">
                          {document.documentElement.dir === "rtl"
                            ? prop.rtlName
                            : prop.name}
                        </Text>
                      </Flex>
                    </Button>
                  )}
                </NavLink>
              )
            )
          })
        }
        <Button
          boxSize="initial"
          justifyContent="flex-start"
          alignItems="center"
          bg="transparent"
          mb={{
            xl: "12px",
          }}
          mx={{
            xl: "auto",
          }}
          py="12px"
          ps={{
            sm: "10px",
            xl: "16px",
          }}
          borderRadius="15px"
          _hover="none"
          w="100%"
          _active={{
            bg: "inherit",
            transform: "none",
            borderColor: "transparent",
          }}
          _focus={{
            boxShadow: "none",
          }}
          onClick={resetSession}
        >
          <Flex>
            <Icon><BiLogOutCircle /></Icon>
            <Text color={inactiveColor} my="auto" fontSize="sm">
              Sair
            </Text>
          </Flex>
        </Button>
      </>
    )
  };

  const { logoText, routes, ...rest } = props;

  var links = <>{createLinks({ routes, setRedirect })}</>;
  //  BRAND
  //  Chakra Color Mode
  let hamburgerColor = useColorModeValue("gray.500", "gray.200");
  if (props.secondary === true) {
    hamburgerColor = "white";
  }
  var brand = (
    <Box pt={"35px"} mb="8px">
      <Link
        href={`${process.env.PUBLIC_URL}/#/`}
        target="_blank"
        display="flex"
        lineHeight="100%"
        mb="30px"
        fontWeight="bold"
        justifyContent="center"
        alignItems="center"
        fontSize="11px"
      >
        <Text fontSize="sm" mt="3px">
          {logoText}
        </Text>
      </Link>
      <Divider></Divider>
    </Box>
  );

  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  // Color variables
  return (
    redirect ? (window.location = '/sign_in') : (
      <Flex
        alignItems="center"
        ml='2'
        style={{cursor: 'pointer'}}
      >
        <FaListUl
          color={hamburgerColor}
          w="18px"
          h="18px"
          ref={btnRef}
          colorScheme="teal"
          onClick={onOpen}
        />
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          placement={document.documentElement.dir === "rtl" ? "right" : "left"}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent
            w="250px"
            maxW="250px"
            ms={{
              sm: "16px",
            }}
            my={{
              sm: "16px",
            }}
            borderRadius="16px"
          >
            <DrawerCloseButton
              _focus={{ boxShadow: "none" }}
              _hover={{ boxShadow: "none" }}
            />
            <DrawerBody maxW="250px" px="1rem" style={{overflow: 'hidden'}}>
              <Box maxW="100%" h="100vh">
                <Box>{brand}</Box>
                <Stack direction="column" mb="40px">
                  <Box>{links}</Box>
                </Stack>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    )
  );
}

export default SidebarResponsive
