// Feito com ❤️  por <a href="https://t.me/@Silverhand/">@Silverhand</a>
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Flex,
  Heading,
  Center,
  Select,
  Input,
  Button,
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

import SessionService from '../../services/session';
import ConsultsService from '../../services/consults';

const Consults = () => {
  const userSession = localStorage.getItem('user_token');
  const [credits, setCredits] = useState(0);
  const [query, setQuery] = useState('');
  const [result, setResult] = useState({});
  const consultTypes = [
    {
      label: 'CPF',
      value: 'cpf',
    },
    {
      label: 'Nome',
      value: 'name'
    },
    {
      label: 'Telefone',
      value: 'phone'
    }
  ];
  const [selectedType, setSelectedType] = useState(consultTypes[0]);

  const fetchSession = async () => {
    const response = await SessionService.show();
    setCredits(response.data.credits);
  };

  const selectTypeAndConsult = async (e) => {
    e.preventDefault();

    const params = {
      cpf: selectedType.value === 'cpf' ? query : '',
      phone: selectedType.value === 'phone' ? query : '',
      name: selectedType.value === 'name' ? query : '',
    };

    let response;
    try {
      switch(selectedType.value) {
        case 'cpf':
          response = await ConsultsService.cpf(params);
          await ConsultsService.debit();

          if (response.data.data.CONTATOS.CPF) {
            toast.success('Consulta realizada com sucesso');
            setResult(response.data);
          } else {
            toast.warning('Dados não encontrados');
          }
          break;
        case 'phone':
          response = await ConsultsService.phone(params);
          await ConsultsService.debit();

          if (response.data.data.length > 0) {
            toast.success('Consulta realizada com sucesso');
            setResult(response.data);
          } else {
            toast.warning('Dados não encontrados');
          }
          break;
        case 'name':
          response = await ConsultsService.name(params);
          await ConsultsService.debit();

          if (response.data.data.length > 0) {
            toast.success('Consulta realizada com sucesso');
            setResult(response.data);
          } else {
            toast.warning('Dados não encontrados');
          }
          break;
        default:
          response = null
      }
    } catch (e) {
      console.log(e);
      toast.error('Verifique os campos de sua consulta ou chame o suporte');
    }
  }

  const setQueryAndApplyMask = (e) => {
    const currentQuery = e.target.value;

    if (query.length > currentQuery.length) {
      setQuery(currentQuery);
    } else {
      switch(selectedType.value) {
        case 'cpf':
          if (currentQuery.length === 3 || currentQuery.length === 7) {
            setQuery(`${currentQuery}.`);
          } else if (currentQuery.length === 11) {
            setQuery(`${currentQuery}-`);
          } else {
            setQuery(currentQuery);
          }
          break;
        case 'phone':
          if (currentQuery.length === 1) {
            setQuery(`(${currentQuery}`);
          } else if (currentQuery.length === 3) {
            setQuery(`${currentQuery}) `);
          } else if (currentQuery.length === 10) {
            setQuery(`${currentQuery}-`);
          } else {
            setQuery(currentQuery);
          }
          break;
        default:
          setQuery(currentQuery);
      }
    }
  }

  useEffect(() => {
    fetchSession();
  });

  return (
    userSession && credits > 0 ? (
      <>
        <Flex direction="column">
          <Heading size='md'>Consulta Selecionada: {selectedType.label}</Heading>
          <br />
          <Select
            onChange={e => { setQuery(''); setResult({}); setSelectedType(consultTypes.filter((type) => type.value === e.target.value)[0])} }
            selected={selectedType.value}
          >
            {
              consultTypes.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))
            }
          </Select>
        </Flex>
        <br />

        <Flex direction="column">
          <form onSubmit={(e) => { selectTypeAndConsult(e) } }>
            {selectedType.value === 'name' ? (
              <Input placeholder={`Digite o ${selectedType.label}`} value={query} onChange={e => setQueryAndApplyMask(e)} />
            ) : (
              <Input
                placeholder={`Digite o ${selectedType.label}`}
                value={query}
                maxLength={selectedType.value === 'cpf' ? 14 : 15}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={e => setQueryAndApplyMask(e)} />
            )}

            <Center>
              <Button colorScheme='teal' variant='outline' type='submit' style={{ marginTop: '20px' }}>
                Consultar
              </Button>
            </Center>
          </form>
        </Flex>

        { Object.keys(result).length > 0 && selectedType.value === 'cpf' ? (
          <Box
            borderWidth='1px'
            borderRadius='lg'
            marginTop='20px'
            display='flex'
            padding="10px"
            flexDirection="column"
            justifyContent="center"
            align="center"
          >
            <Box
              color='white.500'
              fontWeight='semibold'
              letterSpacing='wide'
              textTransform='uppercase'
            >
              <Box ml='8' mr='5' as='heading'>
                Resultado
              </Box>
              <TableContainer>
                <Table variant='simple'>
                  <TableCaption>Serasa Dados Principais</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>Nome</Th>
                      <Th>CPF</Th>
                      <Th>RG</Th>
                      <Th>Sexo</Th>
                      <Th>Data De Nascimento</Th>
                      <Th>Renda</Th>
                      <Th>Nome da Mãe</Th>
                      <Th>Nome do Pai</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>{result?.data?.CONTATOS?.NOME}</Td>
                      <Td>{result?.data?.CONTATOS?.CPF?.length > 0 ? (`${result?.data?.CONTATOS?.CPF.substring(0, 3)}.${result?.data?.CONTATOS?.CPF.substring(3, 6)}.${result?.data?.CONTATOS?.CPF.substring(6, 9)}-${result?.data?.CONTATOS?.CPF.substring(9, 12)}`) : ('')}</Td>
                      <Td>{result?.data?.CONTATOS?.RG}</Td>
                      <Td>{result?.data?.CONTATOS?.SEXO}</Td>
                      <Td>{result?.data?.CONTATOS?.NASC?.length > 0 ? (`${result?.data?.CONTATOS?.NASC.split('-')[2].substring(0, 2)}/${result?.data?.CONTATOS?.NASC.split('-')[1]}/${result?.data?.CONTATOS?.NASC.split('-')[0]}`) : ('')}</Td>
                      <Td>R$ {result?.data?.CONTATOS?.RENDA}</Td>
                      <Td>{result?.data?.CONTATOS?.NOME_MAE}</Td>
                      <Td>{result?.data?.CONTATOS?.NOME_PAI}</Td>
                    </Tr>
                  </Tbody>
                  <Tfoot>
                    <Tr>
                    </Tr>
                  </Tfoot>
                </Table>
                <Table variant='simple'>
                  <TableCaption>Serasa Endereços</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>Rua</Th>
                      <Th>Numero</Th>
                      <Th>Complemento</Th>
                      <Th>Bairro</Th>
                      <Th>Cidade</Th>
                      <Th>Estado</Th>
                      <Th>CEP</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {result?.data?.ENDERECOS.map((endereco) => (
                      <Tr>
                        <Td>{endereco?.LOGR_TIPO} {endereco?.LOGR_NOME}</Td>
                        <Td>{endereco?.LOGR_NUMERO}</Td>
                        <Td>{endereco?.LOGR_COMPLEMENTO}</Td>
                        <Td>{endereco?.BAIRRO}</Td>
                        <Td>{endereco?.CIDADE}</Td>
                        <Td>{endereco?.UF}</Td>
                        <Td>{endereco?.CEP}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        ) : ('') }

        { Object.keys(result).length > 0 && selectedType.value === 'name' ? (
          <Box
            borderWidth='1px'
            borderRadius='lg'
            marginTop='20px'
            display='flex'
            padding="10px"
            flexDirection="column"
            justifyContent="center"
            align="center"
          >
            <Box
              color='white.500'
              fontWeight='semibold'
              letterSpacing='wide'
              textTransform='uppercase'
            >
              <Box ml='8' mr='5' as='heading'>
                Resultado
              </Box>
              <TableContainer>
                { result.data.map((data) => (
                  <>
                    <Table variant='simple'>
                      <TableCaption>Dados Principais</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Nome</Th>
                          <Th>CPF</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{data?.nome}</Td>
                          <Td>{data?.cpf}</Td>
                        </Tr>
                      </Tbody>
                      <Tfoot>
                        <Tr>
                        </Tr>
                      </Tfoot>
                    </Table>
                    <Table variant='simple'>
                      <TableCaption>Endereços</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Rua</Th>
                          <Th>Numero</Th>
                          <Th>Complemento</Th>
                          <Th>Bairro</Th>
                          <Th>Cidade</Th>
                          <Th>Estado</Th>
                          <Th>CEP</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data?.enderecos.map((endereco) => (
                          <Tr>
                            <Td>{endereco?.logradouro}</Td>
                            <Td>{endereco?.numero}</Td>
                            <Td>{endereco?.complemento}</Td>
                            <Td>{endereco?.bairro}</Td>
                            <Td>{endereco?.cidade}</Td>
                            <Td>{endereco?.estado}</Td>
                            <Td>{endereco?.cep}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                        </Tr>
                      </Tfoot>
                    </Table>
                    <Table variant='simple'>
                      <TableCaption>Telefones</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>DDD</Th>
                          <Th>Numero</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data?.telefones.map((phone) => (
                          <Tr>
                            <Td>{phone?.ddd}</Td>
                            <Td>{phone?.telefone}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </>
                ))}
              </TableContainer>
            </Box>
          </Box>
        ) : ('') }

        { Object.keys(result).length > 0 && selectedType.value === 'phone' ? (
          <Box
            borderWidth='1px'
            borderRadius='lg'
            marginTop='20px'
            display='flex'
            padding="10px"
            flexDirection="column"
            justifyContent="center"
            align="center"
          >
            <Box
              color='white.500'
              fontWeight='semibold'
              letterSpacing='wide'
              textTransform='uppercase'
            >
              <Box ml='8' mr='5' as='heading'>
                Resultado
              </Box>
              <TableContainer>
                { result.data.map((data) => (
                  <>
                    <Table variant='simple'>
                      <TableCaption>Dados Principais</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Nome</Th>
                          <Th>CPF</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{data?.nome}</Td>
                          <Td>{data?.documento}</Td>
                        </Tr>
                      </Tbody>
                      <Tfoot>
                        <Tr>
                        </Tr>
                      </Tfoot>
                    </Table>
                    <Table variant='simple'>
                      <TableCaption>Endereços</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>Rua</Th>
                          <Th>Numero</Th>
                          <Th>Complemento</Th>
                          <Th>Bairro</Th>
                          <Th>Cidade</Th>
                          <Th>Estado</Th>
                          <Th>CEP</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                          <Tr>
                            <Td>{data?.endereco?.tipo} {data?.endereco?.logradouro}</Td>
                            <Td>{data?.endereco?.numero}</Td>
                            <Td>{data?.endereco?.complemento}</Td>
                            <Td>{data?.endereco?.bairro}</Td>
                            <Td>{data?.endereco?.cidade}</Td>
                            <Td>{data?.endereco?.uf}</Td>
                            <Td>{data?.endereco?.cep}</Td>
                          </Tr>
                      </Tbody>
                      <Tfoot>
                        <Tr>
                        </Tr>
                      </Tfoot>
                    </Table>
                    <Table variant='simple'>
                      <TableCaption>Telefones</TableCaption>
                      <Thead>
                        <Tr>
                          <Th>DDD</Th>
                          <Th>Numero</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{data?.ddd}</Td>
                          <Td>{data?.telefone}</Td>
                        </Tr>
                      </Tbody>
                      <Tfoot>
                        <Tr>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </>
                ))}
              </TableContainer>
            </Box>
          </Box>
        ) : ('') }
      </>
    ) : (
      <Flex direction="column" style={{textAlign: 'center'}}>
        <Center w='100vw'>
          <Heading size="lg">SEU PLANO NÃO INCLUI ESTE SERVIÇO</Heading>
        </Center>
      </Flex>
    )
  )
}

export default Consults;
