import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  Flex,
  Heading,
  Textarea,
  Button,
  Box,
  Divider,
  useDisclosure,
  Center,
  Grid,
  GridItem,
  Input,
} from '@chakra-ui/react';

import {
  Title,
  Centered,
} from './Plans.styles';

import SessionService from '../../services/session';
import PlanService from '../../services/plan';
import PaymentService from '../../services/payment';

const Plans = (props) => {
  const { onClose } = useDisclosure();
  const [credits, setCredits] = useState(0);
  const [plans, setPlans] = useState([]);
  const [planId, setPlanId] = useState('');
  const [qrCode, setQrCode] = useState({});
  const [paymentId, setPaymentId] = useState('');
  const [paid, setPaid] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchSession = async () => {
    const response = await SessionService.show();
    setCredits(response.data.credits);
  };

  const selectPlan = async (id) => {
    setLoading(true);
    const params = { plan_id: id };
    const response = await PlanService.createPayment(params);
    setPaymentId(response.data.id)
    const currentPaymentId = response.data.id;
    setQrCode({ text: response.data.qrCodeText, base64: response.data.qrCodeBase64 });
    setPlanId(id);
    setLoading(false);

    setInterval(async () => {
      try {
        const paymentResponse = await PaymentService.show(currentPaymentId)
        const status = paymentResponse.data.status
        if (status === 'paid') {
          toast.success('Finalizado com sucesso');
          clearInterval(paymentId)
          setPaid(true)

          setTimeout(() => {
            window.location.href = '/'
          }, 5000)
        }
        if (status === 'canceled') {
          toast.error('Pagamento cancelado!');
          clearInterval(paymentId)
          setTimeout(() => {
            window.location.href = '/'
          }, 3000)
        }
      } catch (error) {
        console.error('Failed to fetch payment status:', error)
      }
    }, 5000)
  }

  const fetchPlans = async () => {
    const response = await PlanService.index();
    setPlans(response.data.plans);
  };

  const fetchPayment = async () => {
    const response = await PaymentService.show(paymentId);
    setQrCode({ text: response.data.qrCodeText, base64: response.data.qrCodeBase64 });
  };

  const copyToClipboard = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        toast.success('Copiado com sucesso');
      })
      .catch((err) => {
        toast.error('Erro ao copiar');
      })
  }

  useEffect(() => {
    onClose();
    fetchSession();
    fetchPlans()
    if (planId !== '') {
      fetchPayment();
    }
  }, []);

  return (
    planId === '' ? (
      <>
        <Grid
          templateAreas={`"header header"
      "main main"
      `}
          gridTemplateRows={'50px 1fr 50px'}
          gridTemplateColumns={'150px 1fr'}
          h='80vh'
          gap='2'
          color='blackAlpha.700'
          fontWeight='bold'
        >
          <GridItem pl='2' area={'header'}>
            <Title as="h1" size="xl">
              Planos
            </Title>
          </GridItem>
          <GridItem pl='2' area={'main'}>
            <Centered>
              {plans.map((plan) => (
                <Box maxW='lg' borderWidth='1px' borderRadius='lg' overflow='hidden' style={{ color: 'white', minWidth: '200px', width: '100vw', padding: '10px', borderColor: 'white', marginBottom: '20px', overflow: 'hidden' }} key={plan.id}>
                  <Centered>
                    <Box
                      mt='1'
                      fontWeight='semibold'
                      as='h4'
                      lineHeight='tight'
                      noOfLines={1}
                      style={{ fontSize: '25px' }}
                    >
                      {plan.name}
                    </Box>

                    <Box
                      style={{ fontSize: '20px', marginTop: '10px' }}
                    >
                      R$ {plan.amount}
                      <Box as='span' color='gray.600' fontSize='sm'>
                        ,00
                      </Box>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                      { loading ? (
                        <Button colorScheme='teal' variant='outline' type='button' isLoading />
                      ) : (
                        <Button colorScheme='teal' variant='outline' type='button' onClick={() => { selectPlan(plan.id) }}>
                          Comprar
                        </Button>
                      ) }
                    </Box>
                  </Centered>
                </Box>
              ))}
            </Centered>
          </GridItem>
        </Grid>
      </>
    ) : (
      <>
        <Flex direction="column">
          <Heading size='md'>{paid ? ('Pago com sucesso') : ('Efetue o pagamento')}</Heading>
        </Flex>

        <br />

        {paid ? ('') : (
          <Flex direction="column">
            <label>Pix Copia e Cola</label>
            <Flex direction="row">
              <Input type="text" name="cookie" value={qrCode.text} disabled={true} placeholder="Pix Copia e Cola" />
              <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(qrCode.text) }}>
                Copiar
              </Button>
            </Flex>

            <br />

            <Centered>
              <img src={`data:image/png;base64,${qrCode.base64}`} alt="QrCode" width="400px" height="400px" />
            </Centered>
          </Flex>
        )}
      </>
    )
  )
}

export default Plans;
