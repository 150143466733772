import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';

export const Main = styled(Flex)`
  width: 100vw;
  height: 90vh;
  overflow: hidden;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const LeftSide = styled(Flex)`
  width: 50vw;
  height: 90vh;
  color: #81E6D9;

  @media (max-width: 600px) {
    width 100%;
    height: auto;
    margin-bottom: 60px;
  }

  & > img {
    width: 35vw;

    @media (max-width: 600px) {
      width: 50vw;
    }
  }

  & > h2 {
    @media (max-width: 600px) {
      display: none;
    }
  }
`;

export const RightSide = styled(Flex)`
  width: 50vw;
  height: 100vh;

  @media (max-width: 600px) {
    width 100%;
    height: auto;
  }
`;

export const CardLogin = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  padding: 30px 35px;
  background: #2f2841;
  border-radius: 20px;
  box-shadow: 0px 10px 40px #00000056;

  @media (max-width: 950px) {
    width: 85%;
  }
`;

export const TextField = styled(Flex)`
  margin: 10px;
  width: 100%;

  & > label {
    margin-bottom: 10px;
    color: #f0ffffde;
  }
`;
