import Api from './api';

const TempMailService = {
  create: () => Api.post('/temp_mail.json'),
  force_create: () => Api.post('/temp_mail.json?generate=true'),
  show: () => Api.get('/temp_mail/0.json'),
  check_mail: (id) => Api.get(`/temp_mail/0/check_mail/${id}.json`),
}

export default TempMailService;
