import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Flex,
  Heading,
  Textarea,
  Button,
  Progress,
  Box,
  Divider,
  useDisclosure,
  Center,
} from '@chakra-ui/react';
import { useParams, Redirect } from 'react-router-dom';

import {
  Centered,
  CustomThumbUp,
  CustomClose,
  CustomCard,
  ColumOnMobileFlex
} from './Checker.styles';

import { Select } from '@chakra-ui/react';

import ZeroAuthService from '../../services/zero_auths';
import DebitsService from '../../services/debits';
import SessionService from '../../services/session';
import sidebarRoutes from '../../utils/sidebarRoutes';

const Checker = (props) => {
  const { onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [checkedCards, setCheckedCards] = useState([]);
  const [finished, setFinished] = useState(false);
  const [total, setTotal] = useState(0);
  const [processed, setProcessed] = useState(0);
  const [credits, setCredits] = useState(0);
  const [pause, setPause] = useState(false);
  const params = useParams();
  const { type } = params;
  const [selectedGate, setSelectedGate] = useState('gate01');
  const abortController = new AbortController();


  const zeroAuthGates = [
    {
      key: 'gate01',
      value: 'gate01',
      label: 'gate01'
    },
  ];

  const debitGates = [
    {
      key: 'gate01',
      value: 'gate01',
      label: 'gate01'
    },
  ];

  const fetchSession = async () => {
    const response = await SessionService.show();
    setCredits(response.data.credits);
  };

  useEffect(() => {
    onClose();
    fetchSession();
    localStorage.setItem('last_path', `/checker/${type}`)
  }, []);

  const cardParams = () => {
    const includingCards = [];

    if (pause) {
      return includingCards;
    }

    for (let i=0; i < cards.length; i++) {
      const card = cards[i];
      if (card) {

        includingCards.push({
          card_number: card.split('|')[0],
          expiration_date: `${card.split('|')[1]}/${card.split('|')[2]}`,
          cvv: card.split('|')[3]
        });
      }
    }

    return includingCards;
  }

  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const checkCards = async (e) => {
    e.preventDefault();
    setPause(false);
    setCheckedCards([]);
    setLoading(true);
    setFinished(false);

    setTotal(cardParams().length);
    toast.success('O teste das infos começou aguarde até finalizar');

    for (const currentCard of cardParams()) {
      try {
        if (!type || type === 'zero_auth') {
          console.log(pause)
          if (pause) { return }

          const response = await ZeroAuthService.create({ list: [currentCard], gateway: selectedGate });

          if (response.status === 200) {
            await loadCheckedCards(response.data.id);
          } else {
            toast.error('something went wrong');
          }
        } else if (type === 'debit') {
          if (pause) { return }
          const response = await DebitsService.create({ list: [currentCard], gateway: selectedGate });

          if (response.status === 200) {
            await loadCheckedCards(response.data.id);
          } else {
            toast.error('something went wrong');
          }
        }
      } catch(e) {
        if (e.message === 'Network Error') {
          if (!type || type === 'zero_auth') {
            console.log(pause)
            if (pause) { return }

            const response = await ZeroAuthService.create({ list: [currentCard], gateway: selectedGate });

            if (response.status === 200) {
              await loadCheckedCards(response.data.id);
            } else {
              toast.error('something went wrong');
            }
          } else if (type === 'debit') {
            if (pause) { return }
            const response = await DebitsService.create({ list: [currentCard], gateway: selectedGate });

            if (response.status === 200) {
              await loadCheckedCards(response.data.id);
            } else {
              toast.error('something went wrong');
            }
          }
        } else {
          setLoading(false);
          toast.error('something went wrong');
        }
      }
    }
  };

  const pauseChecking = (e) => {
    e.preventDefault();

    toast.success('Pausado com sucesso');
    setPause(true);
    setLoading(false);
  }

  const resetFields = () => {
    setFinished(true);
    setLoading(false);
    setProcessed(0);
    setTotal(0);
    cardParams();
  }

  const loadCheckedCards = async (packId) => {
    if (packId === '0') {
      return false;
    }

    toast.success('Info testada com sucesso');

    try {
      const response = await ZeroAuthService.show(packId);

      if (response.status === 200) {
        let currentProcessed = 0;
        setProcessed(prevState => { currentProcessed = prevState + 1; return prevState + 1 });
        setCheckedCards(prevState => { return [...prevState, ...response.data.cards] });
        setCards(
          cards.filter(
            (card) => response.data.cards.filter((checked) => checked.card_number  !== card.split('|')[0]).length
          )
        );

        if (response.data.status === 'finished') {
          if (currentProcessed >= cardParams().length) {
            setCards([]);
            resetFields();
          }
        }
      } else {
        toast.error('something went wrong');
      }
    } catch(e) {
      if (e.message === 'Network Error') {
        const response = await ZeroAuthService.show(packId);

        if (response.status === 200) {
          let currentProcessed = 0;
          setProcessed(prevState => { currentProcessed = prevState + 1; return prevState + 1 });
          setCheckedCards(prevState => { return [...prevState, ...response.data.cards] });
          setCards(
            cards.filter(
              (card) => response.data.cards.filter((checked) => checked.card_number  !== card.split('|')[0]).length
            )
          );

          if (response.data.status === 'finished') {
            if (currentProcessed >= cardParams().length) {
              setCards([]);
              resetFields();
            }
          }
        } else {
          toast.error('something went wrong');
        }
      } else {
        setLoading(false);
        toast.error('something went wrong');
      }
    }
  };

  const userSession = localStorage.getItem('user_token');

  return (
    userSession && credits > 0 ? (
      <>
        <Flex direction="column">
          <Heading size='md'>{type === 'debit' ? type?.split('_')?.join(' ') : 'zero auth'}</Heading>
          <Heading size='md'>Gate Selecionada: {selectedGate.split('-').join(' ')}</Heading>
          <br />
          <Select
            onChange={e => setSelectedGate(e.target.value)}
            selected={selectedGate}
          >
            ${!type || type === 'zero_auth' ? (
              zeroAuthGates.map((option) => (
                <option key={option.key} value={option.value}>{option.label}</option>
              ))
            ): (
              debitGates.map((option) => (
                <option key={option.key} value={option.value}>{option.label}</option>
              ))
            )}
          </Select>
        </Flex>

        <br />

        <Flex direction="column">
          <form onSubmit={(e) => { checkCards(e) } }>
            <label>Lista</label>
            <Textarea
              style={{ textAlign: 'center' }}
              onChange={(e) => { setCards(e.target.value.split("\n")) }}
              value={cards.join("\n")}
              disabled={loading}
              placeholder="numero|mes|ano|cvv"
            />

            <Centered>
              <br />
              { loading ? (
                <>
                  {
                    //<Button colorScheme='teal' variant='outline' onClick={e => pauseChecking(e)}>
                    //Pausar
                    //</Button>
                    //<br />
                    //<br />
                  }
                  <Progress colorScheme='green' value={processed === 0 ? (0) : ((processed/total) * 100)} />
                  <br />
                </>
              ) : (
                <Button colorScheme='teal' variant='outline' type='submit'>
                  Checar
                </Button>
              ) }
            </Centered>
          </form>
        </Flex>

        <br />

        <ColumOnMobileFlex justify="space-between">
          <Box maxW='sm' borderWidth='1px' borderRadius='lg' display='flex' padding="10px">
            <Box
              color='green.500'
              fontWeight='semibold'
              letterSpacing='wide'
              textTransform='uppercase'
              display='flex'
              justifyContent='center'
              alignItems='center'
              w={{lg: 'auto', sm: '320px'}}
            >
              <Box ml='8' mr='5'>
                <CustomThumbUp />
              </Box>
              <Divider orientation='vertical' />

              <Box ml='5'>
                <Heading size="md">{checkedCards.filter((card) => (card.live === true)).length}</Heading>
              </Box>

              <Box ml='5' mr='5'>
                <Heading size="md">Aprovados</Heading>
              </Box>
            </Box>
          </Box>

          <Box maxW='sm' borderWidth='1px' borderRadius='lg' display='flex' padding="10px">
            <Box
              color='red.500'
              fontWeight='semibold'
              letterSpacing='wide'
              textTransform='uppercase'
              display='flex'
              justifyContent='center'
              alignItems='center'
              w={{lg: 'auto', sm: '320px'}}
            >
              <Box ml='8' mr='5'>
                <CustomClose />
              </Box>
              <Divider orientation='vertical' />

              <Box ml='5'>
                <Heading size="md">{checkedCards.filter((card) => (card.live === false || card.live === null)).length}</Heading>
              </Box>

              <Box ml='5' mr='5'>
                <Heading size="md">Reprovados</Heading>
              </Box>
            </Box>
          </Box>

          <Box maxW='sm' borderWidth='1px' borderRadius='lg' display='flex' padding="10px">
            <Box
              color='white.500'
              fontWeight='semibold'
              letterSpacing='wide'
              textTransform='uppercase'
              display='flex'
              justifyContent='center'
              alignItems='center'
              w={{lg: 'auto', sm: '320px'}}
            >
              <Box ml='8' mr='5'>
                <CustomCard />
              </Box>
              <Divider orientation='vertical' />

              <Box ml='5'>
                <Heading size="md">{total}</Heading>
              </Box>

              <Box ml='5' mr='5'>
                <Heading size="md">Todos</Heading>
              </Box>
            </Box>
          </Box>
        </ColumOnMobileFlex>

        <br/>
        <Divider />
        <br/>


        <Box
          borderWidth='1px'
          borderRadius='lg'
          display='flex'
          padding="10px"
          flexDirection="column"
          justifyContent="center"
          align="center"
        >
          <Box
            color='white.500'
            fontWeight='semibold'
            letterSpacing='wide'
            textTransform='uppercase'
          >
            <Box ml='8' mr='5' as='heading'>
              Live
            </Box>

            { checkedCards.map((checkedCard) => {
              return (
                checkedCard.live ? (
                  <Box as="p">
                    { `${checkedCard.card_number} | ${checkedCard.expiration_date.split('/')[0]} | ${checkedCard.expiration_date.split('/')[1]} | ${checkedCard.cvv} | ${checkedCard.brand} | ${checkedCard.bank} | ${checkedCard.scheme} | ${checkedCard.card_type} | ${checkedCard.country}` } | { type === 'debit' ? ('DEBITOU R$ 5,00') : ('VINCULADO COM SUCESSO') } | <a href="http://cibertools.info/" target="_blank">#CYBERTOOLS.INFO</a> | <a href="https://t.me/Silverhand101" target="_blank">@Silverhand101</a>
                  </Box>
                ) : ('')
              )
            }) }
          </Box>
        </Box>

        <br/>

        <Box
          borderWidth='1px'
          borderRadius='lg'
          display='flex'
          padding="10px"
          flexDirection="column"
          justifyContent="center"
          align="center"
        >
          <Box
            color='white.500'
            fontWeight='semibold'
            letterSpacing='wide'
            textTransform='uppercase'
          >
            <Box ml='8' mr='5' as='heading'>
              Die
            </Box>

            { checkedCards.map((checkedCard) => {
              return (
                checkedCard.live ? ('') : (
                  <Box as="p">
                    { `${checkedCard.card_number} | ${checkedCard.expiration_date.split('/')[0]} | ${checkedCard.expiration_date.split('/')[1]} | ${checkedCard.cvv} | ${checkedCard.brand} | ${checkedCard.bank} | ${checkedCard.scheme} | ${checkedCard.card_type} | ${checkedCard.country}` }
                  </Box>
                )
              )
            }) }
          </Box>
        </Box>
      </>
    ) : (
      <Flex direction="column" style={{textAlign: 'center'}}>
        <Center w='100vw'>
          <Heading size="lg">SEU PLANO NÃO INCLUI ESTE SERVIÇO</Heading>
        </Center>
      </Flex>
    )
  );
}

export default Checker;
