import React, { useState } from 'react';
import { Heading, Input, Button, Divider, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { Redirect, NavLink } from 'react-router-dom';

import CreditCard from '../../imgs/credit-card-animation.svg';
import {
  Main,
  LeftSide,
  RightSide,
  CardLogin,
  TextField,
} from './SignIn.styles';
import SessionService from '../../services/session';

const SignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const params = { login: username, password };

    try {
      const response = await SessionService.create(params);

      if (response.status === 200) {
        localStorage.setItem('user_token', response.data.token);
        toast.success('Bem vindo de volta');
        setRedirect(true);
      } else {
        toast.error('Username ou Senha estão incorretos');
      }
    } catch(e) {
      toast.error('Username ou Senha estão incorretos');
    }
  };

  return (
    redirect ? (<Redirect from='sign_in' to='/?reload=true' />) : (
    <Main alignItems="center" justifyContent="center">
      <LeftSide alignItems="center" flexDirection="column" justifyContent="center">
        <Heading fontSize="3vw">Faça Login <br /> E entre para o nosso time</Heading>
        <img src={CreditCard} alt="Credit Card" />
      </LeftSide>

      <RightSide alignItems="center">
        <CardLogin onSubmit={e => handleSubmit(e)}>
          <Heading style={{color: '#81E6D9'}}>LOGIN</Heading>

          <TextField alignItems="flex-start" justifyContent="center" flexDirection="column">
            <label htmlFor="username">Username</label>
            <Input type="text" name="username" placeholder="Digite seu username" value={username} onChange={e => setUsername(e.target.value)} />
          </TextField>

          <TextField alignItems="flex-start" justifyContent="center" flexDirection="column">
            <label htmlFor="password">Senha</label>
            <Input type="password" name="password" placeholder="Digite sua senha" value={password} onChange={e => setPassword(e.target.value)} />
          </TextField>

          <Button colorScheme='teal' variant='outline' mt={4} style={{width: '100%'}} type="submit">Entrar</Button>
          <Divider></Divider>
          <NavLink
            to='/sign_up'
            style={{marginTop: '10px'}}
          >
            <Text>
              Cadastrar
            </Text>
          </NavLink>
        </CardLogin>
      </RightSide>
    </Main>
    )
  )
};

export default SignIn;
