import React from 'react';
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import {
  Grid,
  GridItem,
} from '@chakra-ui/react';

import {
  Title,
  Centered,
  Lunk,
  ButtonWithCustom,
} from './Support.styles';

const Messages = () => {
  return (
    <Grid
      templateAreas={`"header header"
      "main main"
      `}
      gridTemplateRows={'50px 1fr 50px'}
      gridTemplateColumns={'150px 1fr'}
      h='80vh'
      gap='2'
      color='blackAlpha.700'
      fontWeight='bold'
    >
      <GridItem pl='2' area={'header'}>
        <Title as="h1" size="xl">
          Chame o Suporte
        </Title>
      </GridItem>
      <GridItem pl='2' area={'main'}>
        <Centered>
          <Lunk href="https://t.me/Silverhand101" target="_blank">
            <ButtonWithCustom  colorScheme='teal' variant='outline' type='button'>
              <FaTelegramPlane style={{marginRight: '10px'}} />
              Telegram Silverhand
            </ButtonWithCustom >
          </Lunk>
          <br />
          <Lunk href="https://t.me/B1gpurple" target="_blank">
            <ButtonWithCustom  colorScheme='teal' variant='outline' type='button'>
              <FaTelegramPlane style={{marginRight: '10px'}} />
              Telegram Sujiro Kimimame
            </ButtonWithCustom >
          </Lunk>
          {
          //<Lunk href="https://wa.me/+5511997486233" target="_blank">
            //<ButtonWithCustom colorScheme='teal' variant='outline' type='button'>
              //<FaWhatsapp style={{marginRight: '10px'}} />
              //WhatsApp
            //</ButtonWithCustom >
          //</Lunk>
          }
        </Centered>
      </GridItem>
    </Grid>
  );
}

export default Messages;
