import styled from 'styled-components';
import {
  Heading,
  Button,
} from '@chakra-ui/react';

export const Title = styled(Heading)`
  color: white;
  font-size: 22px;
`;

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
`;

export const Lunk = styled.a`
  display: flex;
  text-align: center;
  color: white;
  align-items: center;
  justify-content: center;
`;

export const ButtonWithCustom = styled(Button)`
  width: 400px !important;

  @media (max-width: 400px) {
    width: 200px !important;
  }
`;
