import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaClipboard } from "react-icons/fa";

import {
  Flex,
  Heading,
  Textarea,
  Button,
  Divider,
  useDisclosure,
  Center,
  Grid,
  GridItem,
  Input,
} from '@chakra-ui/react';

import {
  Title,
  Centered,
  Box,
} from './Person.styles';

import SessionService from '../../services/session';
import PersonService from '../../services/person';

const Person = (props) => {
  const { onClose } = useDisclosure();
  const [credits, setCredits] = useState(0);
  const [person, setPerson] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchSession = async () => {
    const response = await SessionService.show();
    setCredits(response.data.credits);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await PersonService.force_create();

      if (response.status === 200) {
        setPerson(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error('Algo deu errado');
      }
    } catch {
      setLoading(false);
      toast.error('Algo deu errado');
    }
  }

  const fetchPerson = async () => {
    try {
      setLoading(true);
      const response = await PersonService.create();

      if (response.status === 200) {
        setPerson(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error('Algo deu errado');
      }
    } catch {
      setLoading(false);
      toast.error('Algo deu errado');
    }
  }

  const copyToClipboard = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        toast.success('Copiado com sucesso');
      })
      .catch((err) => {
        toast.error('Erro ao copiar');
      })
  }

  useEffect(() => {
    onClose();
    fetchSession();
    fetchPerson();
  }, []);

  return (
    <>
      <Grid
        templateAreas={`"header header"
      "main main"
      `}
        gridTemplateRows={'50px 1fr 50px'}
        gridTemplateColumns={'150px 1fr'}
        h='80vh'
        gap='2'
        color='blackAlpha.700'
        fontWeight='bold'
      >
        <GridItem pl='2' area={'header'}>
          <Title as="h1" size="xl">
            Gerador de Pessoas
          </Title>
        </GridItem>
        <GridItem pl='2' area={'main'} style={{ color: 'white' }}>
          <Centered>
            <Flex direction="column">
              <Centered>
                <Flex direction="row">
                  { loading ? (
                    <Button colorScheme='teal' variant='outline' type='button' isLoading />
                  ) : (
                    <Button colorScheme='teal' variant='outline' type='button' onClick={() => { handleSubmit() }}>
                      Gerar
                    </Button>
                  )}
                </Flex>
              </Centered>
              <Box style={{ padding: '20px' }}>
                <label>Nome</label>
                <Flex direction="row">
                  <Input type="text" name="name" value={person.name} placeholder="Nome" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.name) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>CPF</label>
                <Flex direction="row">
                  <Input type="text" name="cpf" value={person.cpf} placeholder="CPF" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.cpf) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>RG</label>
                <Flex direction="row">
                  <Input type="text" name="RG" value={person.rg} placeholder="CPF" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.rg) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Sexo</label>
                <Flex direction="row">
                  <Input type="text" name="sexo" value={person.sex_type} placeholder="Sexo" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.sex_type) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Tipo Sanguíneo</label>
                <Flex direction="row">
                  <Input type="text" name="blood_type" value={person.blood_type} placeholder="Tipo Sanguinieo" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.blood_type) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Email</label>
                <Flex direction="row">
                  <Input type="email" name="email" value={person.email} placeholder="email" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.email) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Gmail</label>
                <Flex direction="row">
                  <Input type="email" name="gmail" value={person.gmail} placeholder="gmail" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.gmail) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Idade</label>
                <Flex direction="row">
                  <Input type="text" name="age" value={person.age} placeholder="idade" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.age) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Data de Nascimento</label>
                <Flex direction="row">
                  <Input type="text" name="birthdate" value={person.birthdate} placeholder="Data de Nascimento" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.birthdate) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Pai</label>
                <Flex direction="row">
                  <Input type="text" name="father" value={person.father} placeholder="Pai" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.father) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Mãe</label>
                <Flex direction="row">
                  <Input type="text" name="mother" value={person.mother} placeholder="Mãe" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.mother) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Telefone</label>
                <Flex direction="row">
                  <Input type="text" name="fix_phone_number" value={person.fix_phone_number} placeholder="Telefone" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.fix_phone_number) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Celular</label>
                <Flex direction="row">
                  <Input type="text" name="phone_number" value={person.phone_number} placeholder="Celular" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.phone_number) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>CEP</label>
                <Flex direction="row">
                  <Input type="text" name="zipcode" value={person.zipcode} placeholder="CEP" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.zipcode) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Endereço</label>
                <Flex direction="row">
                  <Input type="text" name="street" value={person.street} placeholder="Endereço" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.street) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Número</label>
                <Flex direction="row">
                  <Input type="text" name="number" value={person.number} placeholder="Numero" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.number) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Bairro</label>
                <Flex direction="row">
                  <Input type="text" name="neighborhood" value={person.neighborhood} placeholder="bairro" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.neighborhood) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Altura</label>
                <Flex direction="row">
                  <Input type="text" name="height" value={person.height} placeholder="Altura" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.height) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Peso</label>
                <Flex direction="row">
                  <Input type="text" name="weight" value={person.weight} placeholder="Peso" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.weight) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />

                <label>Cor</label>
                <Flex direction="row">
                  <Input type="text" name="color" value={person.color} placeholder="Cor" readonly={true} />
                  <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(person.color) }}>
                    <FaClipboard />
                  </Button>
                </Flex>
                <br />
              </Box>
            </Flex>
          </Centered>
        </GridItem>
      </Grid>
    </>
  )
}

export default Person;
