import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { ChakraProvider, Portal, useDisclosure } from '@chakra-ui/react';
import theme from './theme/theme.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MainPanel from './components/Layout/MainPanel';
import PanelContainer from './components/Layout/PanelContainer';
import PanelContent from './components/Layout/PanelContent';

import Header from './components/Header/Header';

import SignInPage from './pages/SignIn/SignIn';
import SignUpPage from './pages/SignUp/SignUp';
import CheckerPage from './pages/Checker/Checker';
import GGCheckerPage from './pages/GGChecker/GGChecker';
import GGV2CheckerPage from './pages/GGV2Checker/GGV2Checker';
import ItauCheckerPage from './pages/ItauChecker/ItauChecker';
import SeparatorPage from './pages/Separator/Separator';
import UnauthorizedPage from './pages/Unauthorized/Unauthorized';
import DashboardPage from './pages/Dashboard/Dashboard';
import SupportPage from './pages/Support/Support';
import VbvCheckerPage from './pages/VbvChecker/VbvChecker';
import ConsultsPage from './pages/Consults/Consults';
import AmazonPage from './pages/Amazon/Amazon';
import PlansPage from './pages/Plans/Plans';
import RechargePage from './pages/Recharge/Recharge';
import TempMailPage from './pages/TempMail/TempMail';
import PersonPage from './pages/Person/Person';

const Routes = () => {
  const userSession = localStorage.getItem('user_token');

  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <ToastContainer />
      <Router>
        {userSession ? (
          <Header session={userSession} />
        ) : (
          <Header session={null} />
        )}
        <MainPanel
          w={{
            base: '100%',
            xl: '100%'
          }}>
          <PanelContent>
            <PanelContainer>
              {userSession ? (
                <Redirect from="/sign_in" to="/" />
              ) : (
                <>
                  <Route exact path="/sign_in" component={SignInPage} />
                  <Route exact path="/sign_up" component={SignUpPage} />
                </>
              )}
              <Route exact path="/" component={DashboardPage} />
              <Route exact path="/not_authorized" component={UnauthorizedPage} />
              <Route exact path="/checker/:type" component={CheckerPage} />
              <Route exact path="/gg" component={GGCheckerPage} />
              <Route exact path="/gg_all_bins" component={GGV2CheckerPage} />
              <Route exact path="/gg_itau" component={ItauCheckerPage} />
              <Route exact path="/separator" component={SeparatorPage} />
              <Route exact path="/messages" component={SupportPage} />
              <Route exact path="/vbv" component={VbvCheckerPage} />
              <Route exact path="/consults" component={ConsultsPage} />
              <Route exact path="/amazon" component={AmazonPage} />
              <Route exact path="/plans" component={PlansPage} />
              <Route exact path="/recharge" component={RechargePage} />
              <Route exact path="/temp_mail" component={TempMailPage} />
              <Route exact path="/person" component={PersonPage} />
            </PanelContainer>
          </PanelContent>
        </MainPanel>
      </Router>
    </ChakraProvider>
  );
}

export default Routes;
