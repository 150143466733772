import styled from 'styled-components'; 
import { FaThumbsUp, FaCreditCard, FaThumbsDown } from 'react-icons/fa';
import { Flex } from '@chakra-ui/react';

const CustomThumbUp = styled(FaThumbsUp)`
  font-size: 30px;
`;

const CustomClose = styled(FaThumbsDown)`
  font-size: 30px;
`;

const CustomCard = styled(FaCreditCard)`
  font-size: 30px;
`;

const Centered = styled.div`
  width: 100%;
  text-align: center;
`;

const ColumOnMobileFlex = styled(Flex)`
  flex-direction: row;

  @media (max-width: 1090px) {
    flex-direction: column;
    min-width: 320px;
    align-items: center;
  }
`;

export { CustomThumbUp, Centered, ColumOnMobileFlex, CustomClose, CustomCard };
