import { FaCreditCard, FaHome, FaStream, FaHeadset, FaGooglePlus } from 'react-icons/fa';
import { GiSwipeCard, GiBank } from 'react-icons/gi';
import { RiUserSearchFill, RiBankLine } from 'react-icons/ri';
import { BsShieldLockFill, BsShop } from 'react-icons/bs';
import { MdPersonAddAlt1 } from "react-icons/md";
import { IoMailUnread } from "react-icons/io5";


// gates
const dashboardRoute = [
  {
    name: 'DASHBOARD',
    path: '/',
    icon: <FaHome />
  },
];

const gates = localStorage.getItem('gates') ? JSON.parse(localStorage.getItem('gates')) : ({gates: []});
const liveGateNames = gates.gates.filter(gate => gate.live).map(gate => gate.name);

let gateRoutes = [
  {
    id: 'zero_auth',
    name: 'CHECKER ZERO AUTH',
    path: '/checker/zero_auth',
    icon: <FaCreditCard />
  },
  {
    id: 'gg_all_bins',
    name: 'CHECKER GG (ALLBINS)',
    path: '/gg',
    icon: <GiBank />
  },
  {
    id: 'gg_itau',
    name: 'VALIDADOR GG ITAU',
    path: '/gg_itau',
    icon: <FaGooglePlus />
  },
  {
    id: 'vbv',
    name: 'CHECKER VBV',
    path: '/vbv',
    icon: <BsShieldLockFill />
  },
  {
    id: 'debitando',
    name: 'CHECKER DEBITANDO',
    path: '/checker/debit',
    icon: <GiSwipeCard />
  },
].filter((gate) => liveGateNames.includes(gate.id));

const additionalRoutes = [
  {
    name: 'CHECKER AMAZON',
    path: '/amazon',
    icon: <GiBank />
  },
  //{
    //name: 'CONSULTAS',
    //path: '/consults',
    //icon: <RiUserSearchFill />
  //},
  //{
    //name: 'SEPARADOR',
    //path: '/separator',
    //icon: <FaStream />
  //},
  {
    name: 'PLANOS',
    path: '/plans',
    icon: <RiUserSearchFill />
  },
  {
    name: 'RECARGA',
    path: '/recharge',
    icon: <FaStream />
  },
  {
    name: 'EMAIL TEMPORARIO',
    path: '/temp_mail',
    icon: <IoMailUnread />
  },
  {
    name: 'GERAR PESSOA',
    path: '/person',
    icon: <MdPersonAddAlt1 />
  },
  {
    name: 'FALAR COM SUPORTE',
    path: '/messages',
    icon: <FaHeadset />
  }
];

const sidebarRoutes = [...dashboardRoute, ...gateRoutes, ...additionalRoutes];

export default sidebarRoutes;
