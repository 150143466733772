import React from 'react';
import { Flex, Center, Heading } from '@chakra-ui/react';

const UnauthorizedPage = () => {
  return (
    <Flex direction="column" style={{textAlign: 'center'}}>
      <Center w='100vw'>
        <Heading size="lg">SEU PLANO NÃO INCLUI ESTE SERVIÇO</Heading>
      </Center>
    </Flex>
  );
};

export default UnauthorizedPage;
