import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { SlReload } from "react-icons/sl";
import { FaClipboard } from "react-icons/fa";

import {
  Flex,
  Heading,
  Textarea,
  Button,
  Divider,
  useDisclosure,
  Center,
  Grid,
  GridItem,
  Input,
} from '@chakra-ui/react';

import {
  Title,
  Centered,
  Box,
} from './TempMail.styles';

import SessionService from '../../services/session';
import TempMailService from '../../services/temp_mail';

const TempMail = (props) => {
  const { onClose } = useDisclosure();
  const [credits, setCredits] = useState(0);
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [mail, setMail] = useState({ id: '', from: '', subject: '', date: '', body: '' });
  const [loading, setLoading] = useState(false);
  const [mailsLoading, setMailsLoading] = useState(false);

  const fetchSession = async () => {
    const response = await SessionService.show();
    setCredits(response.data.credits);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true)
      setMailsLoading(true);
      toast.success('Gerando um novo email');

      const response = await TempMailService.force_create();

      if (response.status === 200) {
        setEmail(response.data.email);
        setEmails([]);

        toast.success('Email gerado com sucesso');
        setLoading(false);
        setMailsLoading(false);
      } else {
        setLoading(false);
        setMailsLoading(false);
        toast.error('Algo deu errado');
      }
    } catch {
      setLoading(false);
      setMailsLoading(false);
      toast.error('Algo deu errado');
    };
  }

  const copyToClipboard = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        toast.success('Copiado com sucesso');
      })
      .catch((err) => {
        toast.error('Erro ao copiar');
      })
  }

  const fetchTempMail = async() => {
    try {
      const response = await TempMailService.create();

      if (response.status === 200) {
        setEmail(response.data.email);
        await fetchEmails();
      } else {
        toast.error('Algo deu errado');
      }
    } catch {
      window.location.href = '/'
    };
  };

  const fetchEmails = async() => {
    try {
      setMailsLoading(true);
      const response = await TempMailService.show();

      if (response.status === 200) {
        setEmails(response.data.emails);
        setMail({ id: '', from: '', subject: '', date: '', body: '' });
        setMailsLoading(false);
      } else {
        toast.error('Algo deu errado');
        setMailsLoading(false);
      }
    } catch {
      toast.error('Algo deu errado');
      setMailsLoading(false);
    };
  };

  const fetchMail = async(id) => {
    try {
      setMailsLoading(true);
      const response = await TempMailService.check_mail(id);

      if (response.status === 200) {
        setMail({ id: response.data.id, from: response.data.from, subject: response.data.subject, date: response.data.date, body: response.data.textBody });
        setMailsLoading(false);
      } else {
        toast.error('Algo deu errado');
        setMailsLoading(false);
      }
    } catch {
      toast.error('Algo deu errado');
      setMailsLoading(false);
    };
  };


  useEffect(() => {
    onClose();
    fetchSession();
    fetchTempMail();
  }, []);

  return (
    <>
      <Grid
        templateAreas={`"header header"
      "main main"
      `}
        gridTemplateRows={'50px 1fr 50px'}
        gridTemplateColumns={'150px 1fr'}
        h='80vh'
        gap='2'
        color='blackAlpha.700'
        fontWeight='bold'
      >
        <GridItem pl='2' area={'header'}>
          <Title as="h1" size="xl">
            TempMail
          </Title>
        </GridItem>
        <GridItem pl='2' area={'main'} style={{ color: 'white' }}>
          <Centered>
            <Flex direction="column">
              <label>Email</label>
              <Flex direction="row" style={{ marginBottom: '50px', maxWidth: '400px' }}>
                <Input type="email" name="email" value={email} onChange={e => { setEmail(email) }} placeholder="Email..." readonly={true} />
                { loading ? (
                  <Button colorScheme='teal' variant='outline' type='button' isLoading />
                ) : (
                  <>
                    { email !== '' ? (
                      <>
                        <Button colorScheme='teal' variant='outline' type='button' onClick={() => { copyToClipboard(email) }} style={{ marginLeft: '10px' }}>
                          <FaClipboard />
                        </Button>
                        <Button colorScheme='teal' variant='outline' type='button' onClick={() => { fetchEmails() }} style={{ marginLeft: '10px' }}>
                          <SlReload />
                        </Button>
                      </>
                    ) : ('') }
                    <Button colorScheme='teal' variant='outline' type='button' onClick={() => { handleSubmit() }} style={{ marginLeft: '10px' }}>
                      Gerar
                    </Button>
                  </>
                ) }
              </Flex>

              <label>Emails Recebidos</label>
              <Box>
                <Flex direction="row">
                  {
                    mailsLoading ? (
                      <Centered>
                        Carregando Emails ...
                      </Centered>
                    ) : (
                      <>
                        {
                          mail.id === '' ? (
                            <>
                              {
                                emails.length > 0 ? (
                                  <>
                                    {
                                      emails.map((currentMail) => (
                                        <>
                                          <Flex direction="column" style={{margin: '20px', width: '100%', cursor: 'pointer'}} onClick={() => fetchMail(currentMail.id)} key={currentMail.id}>
                                            <p>De: <strong>{currentMail.from}</strong></p>
                                            <p>Assunto: <strong>{currentMail.subject}</strong></p>
                                            <Divider style={{ width: '100%;' }} />
                                          </Flex>
                                        </>
                                      ))
                                    }
                                  </>
                                ) : (
                                  <Centered>
                                    Ainda não chegou nenhum email
                                  </Centered>
                                )
                              }
                            </>
                          ) : (
                            <Flex direction="column" style={{margin: '20px', width: '100%', cursor: 'pointer'}}>
                              <p>De: <strong>{mail.from}</strong></p>
                              <p>Assunto: <strong>{mail.subject}</strong></p>
                              <p>Data: <strong>{mail.date}</strong></p>
                              <div style={{ marginTop: '20px' }}>{mail.body}</div>
                            </Flex>
                          )
                        }
                      </>
                    )
                  }
                </Flex>
              </Box>
            </Flex>
          </Centered>
        </GridItem>
      </Grid>
    </>
  )
}

export default TempMail;
